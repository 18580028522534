import React, { useEffect, useState } from 'react';
import NavigationBar from '../segments/navigationBar';
import { Environment } from '../../context/Environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const Profile = () => {

    const [isEditing, setIsEditing] = useState(false)

    const [profile, setProfile] = useState({
        "username": "",
        "firstname": "",
        "lastname": "",
        "email": "",
        "country": "",
        "code": "",
        "phone": null,
        "password": "",
        "createdOn": null
    })

    const [countries, setCountries] = useState([]);

    const handleEdit = () => {
        setIsEditing(true)
    }

    const handleSave = async () => {

        setIsEditing(false)

        const token = localStorage.getItem("token_habdi010924");

        const paylo = JSON.parse(token);

        const response = await fetch(Environment.getApiUrl() + '/profile', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
                'x-api-key': 'Bearer ' + paylo.token
            },
            body: JSON.stringify(profile)
        });

        const { ok }: any = await response.json();

        if(ok == true)
        {
            Swal.fire({
                title: "Exito",
                html:"Perfil actualizado",
                icon: 'success'
            });

        } else {

            Swal.fire({
                title: "Ops!",
                html:"Sucedio un error inesperado, intentar mas tarde.",
                icon: 'error'
            });

        }
        
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setProfile(prev => ({ ...prev, [name]: value }))
    }

    const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {

        const { name, value } = e.target;

        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));

    };

    useEffect(() => {

        const fetchMovies = async () => {

            try {

                const token = localStorage.getItem("token_habdi010924");

                const paylo = JSON.parse(token);

                const response = await fetch(Environment.getApiUrl() + '/profile', {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                        'x-api-key': 'Bearer ' + paylo.token
                    }
                });

                const { ok, data }: any = await response.json();

                if (ok == true) {
                    setProfile(data);
                    setCountries(data.countries);
                }


            } catch (e) {
                console.log("Error ", e);
            }
        }

        fetchMovies();

    }, []);

    return (
        <div className="bg-gray-100 min-h-screen text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main>
                <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden">
                        <div className="text-center py-4 bg-gray-600">
                            <h1 className="text-2xl font-bold text-white">Perfil de Usuario</h1>
                        </div>
                        <div className="p-6">
                            <div className="flex justify-center mb-6">
                                <FontAwesomeIcon icon={faUser} style={{ fontSize: 48 }} />
                            </div>
                            <form>
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">Nombre de usuario</label>
                                        <input
                                            type="text"
                                            id="username"
                                            name="username"
                                            value={profile.username}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Contraseña</label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            value={profile.password || ""}
                                            onChange={handleChange}
                                            placeholder={isEditing ? "Ingrese nueva contraseña" : "••••••••"}
                                            disabled={!isEditing}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">Nombre</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            value={profile.firstname}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Apellido</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={profile.lastname}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Correo electrónico</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={profile.email}
                                            onChange={handleChange}
                                            disabled
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Teléfono (opcional)</label>
                                        <input
                                            type="phone"
                                            id="phone"
                                            name="phone"
                                            value={profile.phone || ""}
                                            onChange={handleChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">País</label>

                                        <select
                                            id="country"
                                            name='country'
                                            disabled={!isEditing}
                                            value={profile.country || ""}
                                            onChange={handleChangeSelect}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500">

                                            <option value="" disabled>Seleccionar</option>

                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <div>
                                        <label htmlFor="createdOn" className="block text-sm font-medium text-gray-700">Fecha de Registro</label>
                                        <input
                                            type="text"
                                            id="createdOn"
                                            name="createdOn"
                                            value={new Date(profile.createdOn).toLocaleDateString()}
                                            disabled
                                            readOnly
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100"
                                        />
                                    </div>
                                </div>
                                <div className="mt-6 flex justify-end">
                                    {isEditing ? (
                                        <button
                                            type="button"
                                            onClick={handleSave}
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Guardar cambios
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            onClick={handleEdit}
                                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Editar perfil
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    );

}

export default Profile;