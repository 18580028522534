import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './components/screen/Home';
import Videos from './components/screen/Videos';
import Categories from './components/screen/Categories';
import Player from './components/screen/Player';
import Podcast from './components/screen/Podcast';
import Profile from './components/screen/Profile';

import './index.css';
import Login from './components/screen/Login';
import Search from './components/screen/Search';

function App() {

  return (
    <div className='w-full h-screen scroll-smooth' style={{ overflowY: 'scroll'}}>
      
      <Router>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/categories" element={ <Categories />} />
          <Route path="/videos/:category" element={ <Videos />} />
          <Route path="/player/:video" element={ <Player />} />
          <Route path="/podcast" element={ <Podcast/>}></Route>
          <Route path="/login" element={ <Login setAuth={undefined}/> } />
          <Route path="/profile" element={ <Profile />} />
          <Route path='/search/:t' element={ <Search /> }/>
        </Routes>
      </Router>

    </div>
  )

}

export default App;