
const Footer  = ({ }) => {

    return(
        <footer className="bg-gray-100 text-black p-4 relative bottom-0 w-full">
        <div className="container mx-auto">
          <div className="flex justify-around">
            <a href="#" className="hover:text-gray-300">Facebook</a>
            <a href="#" className="hover:text-gray-300">Twitter</a>
            <a href="#" className="hover:text-gray-300">Instagram</a>
          </div>
        </div>
      </footer>
    )
};

export default Footer;